import React from "react";

export const ResumeHeader = () => (
  <header>
    <div>
      <h1>Andrew&nbsp;N. Fabiny</h1>
      <h2>Web Developer</h2>
    </div>
    <hr />
  </header>
);
